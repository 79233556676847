/**
 * objects/_info.scss
 */

 .info {

  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background: #99246A;

  @include media-breakpoint-down(sm){
    padding-top: 1.1875rem;
    padding-bottom: 2rem;
  }

  .title {
    width: 100%;
    max-width: 815px;
  }

  &-redes {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 6rem;

    @include media-breakpoint-down(sm){
      flex-direction: column;
      align-items: center;
      margin-bottom: 3rem;
    }

  }

  &-rede {
    margin: 0 2.6rem;
    text-align: center;
    text-decoration: none;

    @include media-breakpoint-down(sm){
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 3rem;
    }

    svg {
      max-width: 80px;
      height: auto;
      position: relative;
      transform: scale(1);
      transition: all .3s ease;
    }

    span {
      color: var(--colorSecondary);
      display: block;
      margin-top: 1rem;
      transition: all .3s ease;
    }

    &:hover {

      svg {
        transform: scale(1.1);
      }
    }

    &:nth-of-type(1) {

      &:hover {

        span {
          color: rgba(#1976d2, 1);
        }
      }
    }

    &:nth-of-type(2) {

      &:hover {

        span {
          color: rgba(#2cb742, 1);
        }
      }
    }
  }

  .box {
    display: flex;
    background-color: var(--colorTertiary);
    flex-direction: column;
    width: auto;
    max-width: 820px;
    padding: 2rem;

    @include media-breakpoint-down(sm){
      width: 80%;
    }
  }



}
