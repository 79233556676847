/**
 * components/_place.scss
 */
.place {
  display: flex;
  flex-direction: column;

  span {
    margin-top: .6rem;
    font-size: 1.375rem;
    line-height: 2.875rem;
  }

  a {
    font-size: 1rem;
    line-height: 2.875rem;
    text-decoration: none;
    transition: color .3s;

    &:hover {
      color: var(--colorTertiary);

      i, svg, path {
        color: var(--colorTertiary);
        fill: var(--colorTertiary);
      }
    }

    i {
      margin-right: .5rem;
    }
  }
}
