/**
 * components/_input.scss
 */
input {

  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: bold;
  color: #000;
  padding-top: .875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: .875rem;

  border: 2px solid var(--colorSecondary);
  border-radius: .625rem;
  outline: none;
  transition: border .3s ease-out;

  @include media-breakpoint-down(sm){
    font-size: 1.25rem !important;
    line-height: 1.6875rem !important;
  }

  &:focus{
    border-color: var(--colorQuinary);
    transition: border .3s ease-in;
  }
}
