/**
 * components/_footer.scss
 */

.footer {

  width: 100%;
  height: auto;
  background-color: var(--colorTertiary);
  padding-top: 4.9375rem;
  padding-bottom: 5.5rem;

  @include media-breakpoint-down(sm){
    padding-top: 2.375rem;
    padding-bottom: 2.4375rem;
  }

  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". logo . links links links links links links social social .";

    @include media-breakpoint-down(sm){
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
        "logo logo logo logo"
        "links links links links"
        "social social social social";
    }

  }

  .logo {

    display: flex;
    align-items: flex-end;
    grid-area: logo;

    @include media-breakpoint-down(sm){
      justify-content: center;
    }

    a {
      width: 100%;
      display: block;
      max-width: 3.125rem;
      height: auto;

      @include media-breakpoint-down(sm){
        width: 8.8%;
        max-width: unset;
      }
    }

    span, i, svg {
      display: block;
      width: 100%;
      height: 100%;
    }

  }

  .links {
    grid-area: links;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-down(sm){
      margin-top: 1.875rem;

      h3{
        font-size: 1.375rem;
        line-height: 1.5rem;
      }
    }

    ul {
      width: 100%;
      max-width: 37.5rem;
      margin-top: 3rem;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1.375rem;
      line-height: 1.875rem;
      font-weight: bold;
      padding: 0;

      @include media-breakpoint-down(sm){
        flex-direction: column;
        margin-top: 1.5rem;
      }

      li {
        display: block;
        margin-top: .5rem;
        margin-bottom: .5rem;


        @include media-breakpoint-down(sm){
          text-align: center;
        }

        a {
          text-decoration: none;
          cursor: pointer;
          transition: color .3s ease-in-out;

          &:hover{
            color: var(--colorQuinary);
          }
        }
      }
    }
  }

  .container-social {
    grid-area: social;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.5rem;

    @include media-breakpoint-down(sm){
      margin-top: 2rem;
    }

    .social {
      justify-content: flex-end;

      @include media-breakpoint-down(sm){
        justify-content: center;

        a {
          margin-left: .625rem;
          margin-right: .625rem;
        }
      }
    }
  }

}
