@import url(https://fonts.googleapis.com/css?family=Nunito:400,600,700&display=swap);
/** main.scss */
/* ==========================================================================
   Settings
   ========================================================================== */
/** Variables. */
/**
 * settings/_main.scss
 */
:root {
  font-size: 16px; }

* {
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent; }

body {
  --colorPrimary: #EB6625;
  --colorSecondary: #fff;
  --colorTertiary: #273E76;
  --colorQuaternary: #B4196B;
  --colorQuinary: #EC6906;
  --colorSenary: #BC213B;
  --fontPrimary: Nunito, sans-serif;
  background: var(--colorSecondary);
  color: var(--colorSecondary);
  font: 400 1rem/1.7 var(--fontPrimary); }

img {
  max-width: 100%; }

a {
  color: inherit; }

/* ==========================================================================
   Tools
   ========================================================================== */
/** Functions, mixins etc. */
/**
 * tools/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/**
 * generic/_breakpoints.scss
 */
/**
 * generic/_hardware.scss
 */
/**
 * generic/_zindex.scss
 */
/**
 * tools/_spacer.scss
 */
.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-2 {
  margin: 1.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.5rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 2.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 3.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.5rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-2 {
  padding: 1.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.5rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 2.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 3.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.5rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-2 {
    margin: 1.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.5rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 2.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 3.5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.5rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-2 {
    padding: 1.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.5rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 2.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 3.5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.5rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-2 {
    margin: 1.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.5rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 2.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 3.5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.5rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-2 {
    padding: 1.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.5rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 2.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 3.5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.5rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-2 {
    margin: 1.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.5rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 2.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 3.5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.5rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-2 {
    padding: 1.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.5rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 2.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 3.5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.5rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

/* ==========================================================================
   Vendor
   ========================================================================== */
/** Third party components. */
/**
 * vendor/_main.scss
 */
/* ==========================================================================
   Third-party styles
   ========================================================================== */
/* ==========================================================================
   Generic
   ========================================================================== */
/** Low-specificity, far-reaching rulesets. */
/**
 * generic/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/**
 * generic/_fonts.scss
 */
/**
 * generic/_container.scss
 */
.container {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-column-gap: 1rem;
  padding-left: 7.299%;
  padding-right: 7.299%; }

::-moz-selection {
  background-color: var(--colorSecondary);
  color: var(--colorTertiary); }

::selection {
  background-color: var(--colorSecondary);
  color: var(--colorTertiary); }

.center {
  text-align: center; }

.uppercase {
  text-transform: uppercase; }

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0; }
  100% {
    background-position: 468px 0; } }

.animated-background {
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
          animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
          animation-timing-function: linear;
  background: #e3e3e3;
  background-image: -webkit-linear-gradient(left, #e3e3e3 0%, #edeef1 20%, #e3e3e3 40%, #e3e3e3 100%); }

/* ==========================================================================
   Base
   ========================================================================== */
/** Unclassed HTML elements. */
/**
 * base/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/* ==========================================================================
   Objects
   ========================================================================== */
/** Design-free objects, abstractions and patterns. */
/**
 * objects/_main.scss
 */
/**
 * objects/_artists.scss
 */
.artists {
  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4196b+0,872d6a+49,bc213b+100 */
  background: #b4196b;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#b4196b), color-stop(49%, #872d6a), to(#bc213b));
  background: linear-gradient(to bottom, #b4196b 0%, #872d6a 49%, #bc213b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4196b', endColorstr='#bc213b',GradientType=0 );
  /* IE6-9 */ }
  @media (max-width: 991.98px) {
    .artists {
      padding-top: 1.1875rem; } }
  .artists .title {
    width: 100%; }
    @media (max-width: 991.98px) {
      .artists .title {
        margin-top: 2.75rem; } }
  .artists .itens {
    width: 100%;
    margin-top: 2.125rem; }
    @media (max-width: 991.98px) {
      .artists .itens {
        margin-top: 0rem; } }
  .artists .container-form {
    display: grid;
    grid-column-gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . form form form form form form . . .";
    width: 100%;
    margin-top: 0rem;
    margin-bottom: 3.375rem;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . form form form form form form form form . ."; }
    @media (max-width: 991.98px) {
      .artists .container-form {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "form form form form";
        margin-top: 4.43rem;
        margin-bottom: 2.25rem; } }
    .artists .container-form .form {
      grid-area: form;
      justify-self: center; }

/**
 * objects/_calendary.scss
 */
.calendary {
  width: 100%;
  height: auto;
  padding-top: 4.625rem;
  padding-bottom: 4rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#bc213b+0,eb6625+100 */
  background: #bc213b;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#bc213b), to(#eb6625));
  background: linear-gradient(to bottom, #bc213b 0%, #eb6625 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bc213b', endColorstr='#eb6625',GradientType=0 );
  /* IE6-9 */ }
  @media (max-width: 991.98px) {
    .calendary {
      padding-top: 1rem; } }
  .calendary .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". cards cards cards cards cards cards cards cards cards cards ."; }
    @media (max-width: 991.98px) {
      .calendary .container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "cards cards cards cards"; } }
  .calendary .cards {
    display: grid;
    width: 100%;
    grid-area: cards;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem; }
    @media (max-width: 1279.98px) {
      .calendary .cards {
        grid-template-columns: 1fr; } }

/**
 * objects/_locations.scss
 */
.-border-bottom {
  padding-bottom: 4rem;
  border-bottom: 1px solid lightyellow; }

.locations {
  width: 100%;
  height: auto;
  padding-top: 3.1875rem;
  padding-bottom: 5.4375rem;
  background-color: var(--colorPrimary); }
  @media (max-width: 991.98px) {
    .locations {
      padding-bottom: 0; } }
  .locations .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas: "title title title title title title title title title title title title" "locations locations locations locations locations locations locations locations locations locations locations locations"; }
    @media (max-width: 1279.98px) {
      .locations .container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "title title title title" "locations locations locations locations"; } }
    .locations .container h3 {
      grid-area: title; }
      .locations .container h3.-small {
        font-size: 1.4rem;
        padding: 1rem 0 0; }
    .locations .container .container-locations {
      display: flex;
      width: 100%;
      flex-direction: row;
      grid-area: locations;
      margin-top: 3.1875rem;
      justify-content: space-between; }
      @media (max-width: 1279.98px) {
        .locations .container .container-locations {
          flex-direction: column; } }
      .locations .container .container-locations .item {
        flex-grow: 1; }
        .locations .container .container-locations .item h4 {
          margin-bottom: 2rem; }
        @media (max-width: 1279.98px) {
          .locations .container .container-locations .item {
            margin-bottom: 5rem; } }

/**
 * objects/_locked.scss
 */
.locked {
  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4196b+0,872d6a+49,bc213b+100 */
  background: #b4196b;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#b4196b), color-stop(49%, #872d6a), to(#bc213b));
  background: linear-gradient(to bottom, #b4196b 0%, #872d6a 49%, #bc213b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4196b', endColorstr='#bc213b',GradientType=0 );
  /* IE6-9 */ }
  @media (max-width: 991.98px) {
    .locked {
      padding-top: 1.1875rem;
      padding-bottom: 1.1875rem; } }
  .locked .title {
    width: 100%; }
    @media (max-width: 991.98px) {
      .locked .title {
        margin-top: 2.75rem; } }

/**
 * components/_results.scss
 */
.results {
  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  background: #b4196b;
  background: -webkit-gradient(linear, left top, left bottom, from(#b4196b), to(#99246a));
  background: linear-gradient(to bottom, #b4196b 0%, #99246a 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4196b', endColorstr='#99246a',GradientType=0 ); }
  @media (max-width: 991.98px) {
    .results {
      padding-top: 3.5rem; } }
  .results .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto; }
    @media (max-width: 991.98px) {
      .results .container {
        grid-template-columns: 1fr; } }
  .results .h3 {
    max-width: 900px; }

/**
 * objects/_info.scss
 */
.info {
  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
  background: #99246A; }
  @media (max-width: 991.98px) {
    .info {
      padding-top: 1.1875rem;
      padding-bottom: 2rem; } }
  .info .title {
    width: 100%;
    max-width: 815px; }
  .info-redes {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin-bottom: 6rem; }
    @media (max-width: 991.98px) {
      .info-redes {
        flex-direction: column;
        align-items: center;
        margin-bottom: 3rem; } }
  .info-rede {
    margin: 0 2.6rem;
    text-align: center;
    text-decoration: none; }
    @media (max-width: 991.98px) {
      .info-rede {
        margin-left: 0;
        margin-right: 0;
        margin-bottom: 3rem; } }
    .info-rede svg {
      max-width: 80px;
      height: auto;
      position: relative;
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .info-rede span {
      color: var(--colorSecondary);
      display: block;
      margin-top: 1rem;
      -webkit-transition: all .3s ease;
      transition: all .3s ease; }
    .info-rede:hover svg {
      -webkit-transform: scale(1.1);
              transform: scale(1.1); }
    .info-rede:nth-of-type(1):hover span {
      color: #1976d2; }
    .info-rede:nth-of-type(2):hover span {
      color: #2cb742; }
  .info .box {
    display: flex;
    background-color: var(--colorTertiary);
    flex-direction: column;
    width: auto;
    max-width: 820px;
    padding: 2rem; }
    @media (max-width: 991.98px) {
      .info .box {
        width: 80%; } }

/* ==========================================================================
   Components
   ========================================================================== */
/** Components and widgets. */
/**
 * components/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/**
 * components/_artist.scss
 */
.artist {
  width: 100%;
  padding: 0; }
  @media (min-width: 1280px) {
    .artist {
      padding-left: 12.57%;
      padding-right: 12.57%; } }
  @media (min-width: 992px) {
    .artist {
      padding-left: 7.2%;
      padding-right: 7.2%; } }
  @media (max-width: 991.98px) {
    .artist {
      margin-bottom: 3.2rem; } }
  .artist .info-container {
    width: 100%; }
  .artist .cards-container .item:nth-of-type(2), .artist .cards-container .item:nth-of-type(3) {
    display: none; }

/**
 * components/_profile.scss
 */
.profile {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .profile picture {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    max-width: 5.625rem;
    max-height: 5.625rem;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0; }
    @media (max-width: 991.98px) {
      .profile picture {
        grid-area: photo;
        max-width: 4.25rem;
        max-height: 4.25rem; } }
  .profile .name {
    grid-area: nameProfile;
    white-space: nowrap;
    margin-left: 20px; }
  .profile .social {
    width: auto; }

/**
 * components/_card_radio_button.scss
 */
.card-radio-button {
  position: relative;
  width: 100%;
  /* ----------------------------------------------
  * Generated by Animista on 2019-12-9 16:45:12
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */
  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */
  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */ }

@-webkit-keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); } }

@keyframes scale-down-center {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  100% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); } }

@-webkit-keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }

@keyframes scale-up-center {
  0% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
  .card-radio-button input[type='radio'] {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1; }
    .card-radio-button input[type='radio']:not(:checked) + label .content {
      -webkit-animation: scale-up-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-up-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .card-radio-button input[type='radio']:checked + label .content {
      -webkit-animation: scale-down-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
      animation: scale-down-center 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both; }
    .card-radio-button input[type='radio']:checked + label:before {
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      border-radius: 1.25rem;
      overflow: hidden; }
  .card-radio-button label {
    display: block;
    width: 100%;
    height: auto; }
    .card-radio-button label:before {
      content: ''; }
    .card-radio-button label .content {
      display: block;
      width: 100%;
      height: auto; }
      .card-radio-button label .content picture {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 1.125rem;
        overflow: hidden; }
        .card-radio-button label .content picture img {
          width: 100%;
          height: auto; }
      .card-radio-button label .content .check {
        position: absolute;
        width: 2.9rem;
        height: 2.9rem;
        top: 1rem;
        right: 1rem;
        background-color: rgba(255, 255, 255, 0.85);
        border-radius: 1.45rem;
        border: 1px solid rgba(125, 125, 125, 0.6); }
        @media (max-width: 991.98px) {
          .card-radio-button label .content .check {
            width: 2.5rem;
            height: 2.5rem;
            top: .75rem;
            right: .75rem; } }
        .card-radio-button label .content .check .animation {
          position: absolute;
          width: 5.9375rem;
          height: 5.9375rem;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
                  transform: translate(-50%, -50%);
          z-index: 10;
          margin-top: .1rem; }
          @media (max-width: 991.98px) {
            .card-radio-button label .content .check .animation {
              width: 5rem;
              height: 5rem; } }
        .card-radio-button label .content .check span, .card-radio-button label .content .check svg {
          width: 100%;
          height: 100%;
          display: block; }

/**
 * components/_social.scss
 */
.social {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; }
  .social #gradient {
    width: 0;
    height: 0;
    position: absolute; }
  .social a {
    width: 100%;
    max-width: 1.8125rem;
    height: auto;
    text-decoration: none;
    font-size: 0;
    margin-right: 1.25rem; }
    .social a span, .social a i, .social a svg {
      display: block;
      width: 100%;
      height: 100%; }
    .social a svg path {
      -webkit-transition: all 1s ease-out;
      transition: all 1s ease-out;
      fill: var(--colorSecondary); }
    .social a:hover svg path {
      -webkit-transition: all 1s ease-out;
      transition: all 1s ease-out;
      fill: url(#my-share-gradient) var(--colorSecondary); }

/**
 * components/_form.scss
 */
.form {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50.75rem;
  background-color: var(--colorTertiary);
  border-radius: .875rem;
  padding-top: 3.875rem;
  padding-left: 6.9rem;
  padding-right: 6.9rem;
  padding-bottom: 4.375rem; }
  @media (max-width: 991.98px) {
    .form {
      padding-top: 3.125rem;
      padding-left: 6.54%;
      padding-right: 6.54%;
      padding-bottom: 3.06rem; } }
  .form h4 {
    width: 100%; }
    @media (max-width: 991.98px) {
      .form h4 {
        font-size: 1.125rem;
        line-height: 1.75rem; } }
  .form form {
    display: flex;
    flex-direction: column;
    align-items: center; }
  .form .form-group {
    width: 90%;
    margin-top: 2.875rem; }
    @media (max-width: 991.98px) {
      .form .form-group {
        margin-top: 2.06rem; } }
    .form .form-group label {
      display: inline-block;
      font-size: 1.375rem;
      line-height: 1.875rem; }
      @media (max-width: 991.98px) {
        .form .form-group label {
          font-size: 1.125rem;
          line-height: 1.75rem; } }
    .form .form-group .form-control {
      width: 100%;
      margin-top: 1.125rem; }
    .form .form-group small {
      display: inline-block;
      font-size: 1rem;
      line-height: 1.375rem;
      margin-top: .5rem;
      color: var(--colorQuinary); }
    .form .form-group button, .form .form-group a {
      width: 100%;
      display: inline-block; }
    .form .form-group .loading {
      position: relative;
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center; }
      .form .form-group .loading div {
        position: absolute;
        width: auto !important;
        height: auto !important;
        top: 50%;
        -webkit-transform: translate(0%, -50%);
                transform: translate(0%, -50%); }
  .form .share {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem; }
    @media (max-width: 1279.98px) {
      .form .share {
        flex-direction: column; } }
    .form .share h4 {
      width: auto;
      display: inline-flex; }
    .form .share .share-buttons {
      display: inline;
      margin-left: .8rem; }
      @media (max-width: 1279.98px) {
        .form .share .share-buttons {
          margin-left: 0; } }
      .form .share .share-buttons div {
        display: inline;
        margin-left: .1rem;
        margin-right: .1rem;
        cursor: pointer;
        outline: none; }
  .form .animation-success {
    position: fixed;
    width: 40%;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -60%);
            transform: translate(-50%, -60%); }
    @media (max-width: 991.98px) {
      .form .animation-success {
        width: 100%;
        -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%); } }

.slide-in-bottom {
  -webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both;
  animation: slide-in-bottom 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0.5s both; }

/* ----------------------------------------------
 * Generated by Animista on 2019-12-13 17:10:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0; }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1; } }

/**
 * components/_input.scss
 */
input {
  font-size: 1.5rem !important;
  line-height: 2rem !important;
  font-weight: bold;
  color: #000;
  padding-top: .875rem;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-bottom: .875rem;
  border: 2px solid var(--colorSecondary);
  border-radius: .625rem;
  outline: none;
  -webkit-transition: border .3s ease-out;
  transition: border .3s ease-out; }
  @media (max-width: 991.98px) {
    input {
      font-size: 1.25rem !important;
      line-height: 1.6875rem !important; } }
  input:focus {
    border-color: var(--colorQuinary);
    -webkit-transition: border .3s ease-in;
    transition: border .3s ease-in; }

/**
 * components/_card.scss
 */
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3.75rem;
  background-color: #F7E6A2;
  border-radius: .625rem;
  align-items: center;
  justify-content: space-between; }
  @media (max-width: 1279.98px) {
    .card {
      margin-bottom: 2.625rem; } }
  @media (max-width: 991.98px) {
    .card {
      padding-top: 1.8125rem;
      padding-bottom: 1.8125rem; } }
  .card h4 {
    color: var(--colorTertiary);
    display: inline-block; }
  .card span {
    display: inline-block;
    max-width: 21rem;
    font-size: 1.5rem;
    line-height: 2.875rem;
    margin-top: 3rem;
    color: var(--colorPrimary); }
    @media (max-width: 991.98px) {
      .card span {
        max-width: 11.2rem;
        margin-top: .6875rem; } }

/**
 * components/_place.scss
 */
.place {
  display: flex;
  flex-direction: column; }
  .place span {
    margin-top: .6rem;
    font-size: 1.375rem;
    line-height: 2.875rem; }
  .place a {
    font-size: 1rem;
    line-height: 2.875rem;
    text-decoration: none;
    -webkit-transition: color .3s;
    transition: color .3s; }
    .place a:hover {
      color: var(--colorTertiary); }
      .place a:hover i, .place a:hover svg, .place a:hover path {
        color: var(--colorTertiary);
        fill: var(--colorTertiary); }
    .place a i {
      margin-right: .5rem; }

/**
 * components/_footer.scss
 */
.footer {
  width: 100%;
  height: auto;
  background-color: var(--colorTertiary);
  padding-top: 4.9375rem;
  padding-bottom: 5.5rem; }
  @media (max-width: 991.98px) {
    .footer {
      padding-top: 2.375rem;
      padding-bottom: 2.4375rem; } }
  .footer .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". logo . links links links links links links social social ."; }
    @media (max-width: 991.98px) {
      .footer .container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "logo logo logo logo" "links links links links" "social social social social"; } }
  .footer .logo {
    display: flex;
    align-items: flex-end;
    grid-area: logo; }
    @media (max-width: 991.98px) {
      .footer .logo {
        justify-content: center; } }
    .footer .logo a {
      width: 100%;
      display: block;
      max-width: 3.125rem;
      height: auto; }
      @media (max-width: 991.98px) {
        .footer .logo a {
          width: 8.8%;
          max-width: unset; } }
    .footer .logo span, .footer .logo i, .footer .logo svg {
      display: block;
      width: 100%;
      height: 100%; }
  .footer .links {
    grid-area: links;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 991.98px) {
      .footer .links {
        margin-top: 1.875rem; }
        .footer .links h3 {
          font-size: 1.375rem;
          line-height: 1.5rem; } }
    .footer .links ul {
      width: 100%;
      max-width: 37.5rem;
      margin-top: 3rem;
      list-style-type: none;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 1.375rem;
      line-height: 1.875rem;
      font-weight: bold;
      padding: 0; }
      @media (max-width: 991.98px) {
        .footer .links ul {
          flex-direction: column;
          margin-top: 1.5rem; } }
      .footer .links ul li {
        display: block;
        margin-top: .5rem;
        margin-bottom: .5rem; }
        @media (max-width: 991.98px) {
          .footer .links ul li {
            text-align: center; } }
        .footer .links ul li a {
          text-decoration: none;
          cursor: pointer;
          -webkit-transition: color .3s ease-in-out;
          transition: color .3s ease-in-out; }
          .footer .links ul li a:hover {
            color: var(--colorQuinary); }
  .footer .container-social {
    grid-area: social;
    display: flex;
    align-items: flex-end;
    margin-bottom: 1.5rem; }
    @media (max-width: 991.98px) {
      .footer .container-social {
        margin-top: 2rem; } }
    .footer .container-social .social {
      justify-content: flex-end; }
      @media (max-width: 991.98px) {
        .footer .container-social .social {
          justify-content: center; }
          .footer .container-social .social a {
            margin-left: .625rem;
            margin-right: .625rem; } }

/* ==========================================================================
   Trumps
   ========================================================================== */
/** Helper classes and overrides. */
/**
 * trumps/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/** critical.scss */
/* ==========================================================================
   Settings
   ========================================================================== */
/** Variables. */
/**
 * settings/_main.scss
 */
:root {
  font-size: 16px; }

* {
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent; }

body {
  --colorPrimary: #EB6625;
  --colorSecondary: #fff;
  --colorTertiary: #273E76;
  --colorQuaternary: #B4196B;
  --colorQuinary: #EC6906;
  --colorSenary: #BC213B;
  --fontPrimary: Nunito, sans-serif;
  background: var(--colorSecondary);
  color: var(--colorSecondary);
  font: 400 1rem/1.7 var(--fontPrimary); }

img {
  max-width: 100%; }

a {
  color: inherit; }

/* ==========================================================================
   Tools
   ========================================================================== */
/** Functions, mixins etc. */
/**
 * tools/_main.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/**
 * generic/_breakpoints.scss
 */
/**
 * generic/_hardware.scss
 */
/**
 * generic/_zindex.scss
 */
/**
 * tools/_spacer.scss
 */
.m-0 {
  margin: 0 !important; }

.mt-0,
.my-0 {
  margin-top: 0 !important; }

.mr-0,
.mx-0 {
  margin-right: 0 !important; }

.mb-0,
.my-0 {
  margin-bottom: 0 !important; }

.ml-0,
.mx-0 {
  margin-left: 0 !important; }

.m-1 {
  margin: 1rem !important; }

.mt-1,
.my-1 {
  margin-top: 1rem !important; }

.mr-1,
.mx-1 {
  margin-right: 1rem !important; }

.mb-1,
.my-1 {
  margin-bottom: 1rem !important; }

.ml-1,
.mx-1 {
  margin-left: 1rem !important; }

.m-2 {
  margin: 1.5rem !important; }

.mt-2,
.my-2 {
  margin-top: 1.5rem !important; }

.mr-2,
.mx-2 {
  margin-right: 1.5rem !important; }

.mb-2,
.my-2 {
  margin-bottom: 1.5rem !important; }

.ml-2,
.mx-2 {
  margin-left: 1.5rem !important; }

.m-3 {
  margin: 2rem !important; }

.mt-3,
.my-3 {
  margin-top: 2rem !important; }

.mr-3,
.mx-3 {
  margin-right: 2rem !important; }

.mb-3,
.my-3 {
  margin-bottom: 2rem !important; }

.ml-3,
.mx-3 {
  margin-left: 2rem !important; }

.m-4 {
  margin: 2.5rem !important; }

.mt-4,
.my-4 {
  margin-top: 2.5rem !important; }

.mr-4,
.mx-4 {
  margin-right: 2.5rem !important; }

.mb-4,
.my-4 {
  margin-bottom: 2.5rem !important; }

.ml-4,
.mx-4 {
  margin-left: 2.5rem !important; }

.m-5 {
  margin: 3rem !important; }

.mt-5,
.my-5 {
  margin-top: 3rem !important; }

.mr-5,
.mx-5 {
  margin-right: 3rem !important; }

.mb-5,
.my-5 {
  margin-bottom: 3rem !important; }

.ml-5,
.mx-5 {
  margin-left: 3rem !important; }

.m-6 {
  margin: 3.5rem !important; }

.mt-6,
.my-6 {
  margin-top: 3.5rem !important; }

.mr-6,
.mx-6 {
  margin-right: 3.5rem !important; }

.mb-6,
.my-6 {
  margin-bottom: 3.5rem !important; }

.ml-6,
.mx-6 {
  margin-left: 3.5rem !important; }

.m-7 {
  margin: 4rem !important; }

.mt-7,
.my-7 {
  margin-top: 4rem !important; }

.mr-7,
.mx-7 {
  margin-right: 4rem !important; }

.mb-7,
.my-7 {
  margin-bottom: 4rem !important; }

.ml-7,
.mx-7 {
  margin-left: 4rem !important; }

.p-0 {
  padding: 0 !important; }

.pt-0,
.py-0 {
  padding-top: 0 !important; }

.pr-0,
.px-0 {
  padding-right: 0 !important; }

.pb-0,
.py-0 {
  padding-bottom: 0 !important; }

.pl-0,
.px-0 {
  padding-left: 0 !important; }

.p-1 {
  padding: 1rem !important; }

.pt-1,
.py-1 {
  padding-top: 1rem !important; }

.pr-1,
.px-1 {
  padding-right: 1rem !important; }

.pb-1,
.py-1 {
  padding-bottom: 1rem !important; }

.pl-1,
.px-1 {
  padding-left: 1rem !important; }

.p-2 {
  padding: 1.5rem !important; }

.pt-2,
.py-2 {
  padding-top: 1.5rem !important; }

.pr-2,
.px-2 {
  padding-right: 1.5rem !important; }

.pb-2,
.py-2 {
  padding-bottom: 1.5rem !important; }

.pl-2,
.px-2 {
  padding-left: 1.5rem !important; }

.p-3 {
  padding: 2rem !important; }

.pt-3,
.py-3 {
  padding-top: 2rem !important; }

.pr-3,
.px-3 {
  padding-right: 2rem !important; }

.pb-3,
.py-3 {
  padding-bottom: 2rem !important; }

.pl-3,
.px-3 {
  padding-left: 2rem !important; }

.p-4 {
  padding: 2.5rem !important; }

.pt-4,
.py-4 {
  padding-top: 2.5rem !important; }

.pr-4,
.px-4 {
  padding-right: 2.5rem !important; }

.pb-4,
.py-4 {
  padding-bottom: 2.5rem !important; }

.pl-4,
.px-4 {
  padding-left: 2.5rem !important; }

.p-5 {
  padding: 3rem !important; }

.pt-5,
.py-5 {
  padding-top: 3rem !important; }

.pr-5,
.px-5 {
  padding-right: 3rem !important; }

.pb-5,
.py-5 {
  padding-bottom: 3rem !important; }

.pl-5,
.px-5 {
  padding-left: 3rem !important; }

.p-6 {
  padding: 3.5rem !important; }

.pt-6,
.py-6 {
  padding-top: 3.5rem !important; }

.pr-6,
.px-6 {
  padding-right: 3.5rem !important; }

.pb-6,
.py-6 {
  padding-bottom: 3.5rem !important; }

.pl-6,
.px-6 {
  padding-left: 3.5rem !important; }

.p-7 {
  padding: 4rem !important; }

.pt-7,
.py-7 {
  padding-top: 4rem !important; }

.pr-7,
.px-7 {
  padding-right: 4rem !important; }

.pb-7,
.py-7 {
  padding-bottom: 4rem !important; }

.pl-7,
.px-7 {
  padding-left: 4rem !important; }

.m-auto {
  margin: auto !important; }

.mt-auto,
.my-auto {
  margin-top: auto !important; }

.mr-auto,
.mx-auto {
  margin-right: auto !important; }

.mb-auto,
.my-auto {
  margin-bottom: auto !important; }

.ml-auto,
.mx-auto {
  margin-left: auto !important; }

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important; }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important; }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important; }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important; }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important; }
  .m-sm-1 {
    margin: 1rem !important; }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 1rem !important; }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 1rem !important; }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 1rem !important; }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 1rem !important; }
  .m-sm-2 {
    margin: 1.5rem !important; }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1.5rem !important; }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1.5rem !important; }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1.5rem !important; }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1.5rem !important; }
  .m-sm-3 {
    margin: 2rem !important; }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 2rem !important; }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 2rem !important; }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 2rem !important; }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 2rem !important; }
  .m-sm-4 {
    margin: 2.5rem !important; }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 2.5rem !important; }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 2.5rem !important; }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 2.5rem !important; }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 2.5rem !important; }
  .m-sm-5 {
    margin: 3rem !important; }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 3rem !important; }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 3rem !important; }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 3rem !important; }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 3rem !important; }
  .m-sm-6 {
    margin: 3.5rem !important; }
  .mt-sm-6,
  .my-sm-6 {
    margin-top: 3.5rem !important; }
  .mr-sm-6,
  .mx-sm-6 {
    margin-right: 3.5rem !important; }
  .mb-sm-6,
  .my-sm-6 {
    margin-bottom: 3.5rem !important; }
  .ml-sm-6,
  .mx-sm-6 {
    margin-left: 3.5rem !important; }
  .m-sm-7 {
    margin: 4rem !important; }
  .mt-sm-7,
  .my-sm-7 {
    margin-top: 4rem !important; }
  .mr-sm-7,
  .mx-sm-7 {
    margin-right: 4rem !important; }
  .mb-sm-7,
  .my-sm-7 {
    margin-bottom: 4rem !important; }
  .ml-sm-7,
  .mx-sm-7 {
    margin-left: 4rem !important; }
  .p-sm-0 {
    padding: 0 !important; }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important; }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important; }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important; }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important; }
  .p-sm-1 {
    padding: 1rem !important; }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 1rem !important; }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 1rem !important; }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 1rem !important; }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 1rem !important; }
  .p-sm-2 {
    padding: 1.5rem !important; }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1.5rem !important; }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1.5rem !important; }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1.5rem !important; }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1.5rem !important; }
  .p-sm-3 {
    padding: 2rem !important; }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 2rem !important; }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 2rem !important; }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 2rem !important; }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 2rem !important; }
  .p-sm-4 {
    padding: 2.5rem !important; }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 2.5rem !important; }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 2.5rem !important; }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 2.5rem !important; }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 2.5rem !important; }
  .p-sm-5 {
    padding: 3rem !important; }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 3rem !important; }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 3rem !important; }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 3rem !important; }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 3rem !important; }
  .p-sm-6 {
    padding: 3.5rem !important; }
  .pt-sm-6,
  .py-sm-6 {
    padding-top: 3.5rem !important; }
  .pr-sm-6,
  .px-sm-6 {
    padding-right: 3.5rem !important; }
  .pb-sm-6,
  .py-sm-6 {
    padding-bottom: 3.5rem !important; }
  .pl-sm-6,
  .px-sm-6 {
    padding-left: 3.5rem !important; }
  .p-sm-7 {
    padding: 4rem !important; }
  .pt-sm-7,
  .py-sm-7 {
    padding-top: 4rem !important; }
  .pr-sm-7,
  .px-sm-7 {
    padding-right: 4rem !important; }
  .pb-sm-7,
  .py-sm-7 {
    padding-bottom: 4rem !important; }
  .pl-sm-7,
  .px-sm-7 {
    padding-left: 4rem !important; }
  .m-sm-auto {
    margin: auto !important; }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important; }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important; }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important; }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important; } }

@media (min-width: 992px) {
  .m-md-0 {
    margin: 0 !important; }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important; }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important; }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important; }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important; }
  .m-md-1 {
    margin: 1rem !important; }
  .mt-md-1,
  .my-md-1 {
    margin-top: 1rem !important; }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 1rem !important; }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 1rem !important; }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 1rem !important; }
  .m-md-2 {
    margin: 1.5rem !important; }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1.5rem !important; }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1.5rem !important; }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1.5rem !important; }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1.5rem !important; }
  .m-md-3 {
    margin: 2rem !important; }
  .mt-md-3,
  .my-md-3 {
    margin-top: 2rem !important; }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 2rem !important; }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 2rem !important; }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 2rem !important; }
  .m-md-4 {
    margin: 2.5rem !important; }
  .mt-md-4,
  .my-md-4 {
    margin-top: 2.5rem !important; }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 2.5rem !important; }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 2.5rem !important; }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 2.5rem !important; }
  .m-md-5 {
    margin: 3rem !important; }
  .mt-md-5,
  .my-md-5 {
    margin-top: 3rem !important; }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 3rem !important; }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 3rem !important; }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 3rem !important; }
  .m-md-6 {
    margin: 3.5rem !important; }
  .mt-md-6,
  .my-md-6 {
    margin-top: 3.5rem !important; }
  .mr-md-6,
  .mx-md-6 {
    margin-right: 3.5rem !important; }
  .mb-md-6,
  .my-md-6 {
    margin-bottom: 3.5rem !important; }
  .ml-md-6,
  .mx-md-6 {
    margin-left: 3.5rem !important; }
  .m-md-7 {
    margin: 4rem !important; }
  .mt-md-7,
  .my-md-7 {
    margin-top: 4rem !important; }
  .mr-md-7,
  .mx-md-7 {
    margin-right: 4rem !important; }
  .mb-md-7,
  .my-md-7 {
    margin-bottom: 4rem !important; }
  .ml-md-7,
  .mx-md-7 {
    margin-left: 4rem !important; }
  .p-md-0 {
    padding: 0 !important; }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important; }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important; }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important; }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important; }
  .p-md-1 {
    padding: 1rem !important; }
  .pt-md-1,
  .py-md-1 {
    padding-top: 1rem !important; }
  .pr-md-1,
  .px-md-1 {
    padding-right: 1rem !important; }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 1rem !important; }
  .pl-md-1,
  .px-md-1 {
    padding-left: 1rem !important; }
  .p-md-2 {
    padding: 1.5rem !important; }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1.5rem !important; }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1.5rem !important; }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1.5rem !important; }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1.5rem !important; }
  .p-md-3 {
    padding: 2rem !important; }
  .pt-md-3,
  .py-md-3 {
    padding-top: 2rem !important; }
  .pr-md-3,
  .px-md-3 {
    padding-right: 2rem !important; }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 2rem !important; }
  .pl-md-3,
  .px-md-3 {
    padding-left: 2rem !important; }
  .p-md-4 {
    padding: 2.5rem !important; }
  .pt-md-4,
  .py-md-4 {
    padding-top: 2.5rem !important; }
  .pr-md-4,
  .px-md-4 {
    padding-right: 2.5rem !important; }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 2.5rem !important; }
  .pl-md-4,
  .px-md-4 {
    padding-left: 2.5rem !important; }
  .p-md-5 {
    padding: 3rem !important; }
  .pt-md-5,
  .py-md-5 {
    padding-top: 3rem !important; }
  .pr-md-5,
  .px-md-5 {
    padding-right: 3rem !important; }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 3rem !important; }
  .pl-md-5,
  .px-md-5 {
    padding-left: 3rem !important; }
  .p-md-6 {
    padding: 3.5rem !important; }
  .pt-md-6,
  .py-md-6 {
    padding-top: 3.5rem !important; }
  .pr-md-6,
  .px-md-6 {
    padding-right: 3.5rem !important; }
  .pb-md-6,
  .py-md-6 {
    padding-bottom: 3.5rem !important; }
  .pl-md-6,
  .px-md-6 {
    padding-left: 3.5rem !important; }
  .p-md-7 {
    padding: 4rem !important; }
  .pt-md-7,
  .py-md-7 {
    padding-top: 4rem !important; }
  .pr-md-7,
  .px-md-7 {
    padding-right: 4rem !important; }
  .pb-md-7,
  .py-md-7 {
    padding-bottom: 4rem !important; }
  .pl-md-7,
  .px-md-7 {
    padding-left: 4rem !important; }
  .m-md-auto {
    margin: auto !important; }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important; }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important; }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important; }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important; } }

@media (min-width: 1280px) {
  .m-lg-0 {
    margin: 0 !important; }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important; }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important; }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important; }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important; }
  .m-lg-1 {
    margin: 1rem !important; }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 1rem !important; }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 1rem !important; }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 1rem !important; }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 1rem !important; }
  .m-lg-2 {
    margin: 1.5rem !important; }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1.5rem !important; }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1.5rem !important; }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1.5rem !important; }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1.5rem !important; }
  .m-lg-3 {
    margin: 2rem !important; }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 2rem !important; }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 2rem !important; }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 2rem !important; }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 2rem !important; }
  .m-lg-4 {
    margin: 2.5rem !important; }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 2.5rem !important; }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 2.5rem !important; }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 2.5rem !important; }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 2.5rem !important; }
  .m-lg-5 {
    margin: 3rem !important; }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 3rem !important; }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 3rem !important; }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 3rem !important; }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 3rem !important; }
  .m-lg-6 {
    margin: 3.5rem !important; }
  .mt-lg-6,
  .my-lg-6 {
    margin-top: 3.5rem !important; }
  .mr-lg-6,
  .mx-lg-6 {
    margin-right: 3.5rem !important; }
  .mb-lg-6,
  .my-lg-6 {
    margin-bottom: 3.5rem !important; }
  .ml-lg-6,
  .mx-lg-6 {
    margin-left: 3.5rem !important; }
  .m-lg-7 {
    margin: 4rem !important; }
  .mt-lg-7,
  .my-lg-7 {
    margin-top: 4rem !important; }
  .mr-lg-7,
  .mx-lg-7 {
    margin-right: 4rem !important; }
  .mb-lg-7,
  .my-lg-7 {
    margin-bottom: 4rem !important; }
  .ml-lg-7,
  .mx-lg-7 {
    margin-left: 4rem !important; }
  .p-lg-0 {
    padding: 0 !important; }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important; }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important; }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important; }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important; }
  .p-lg-1 {
    padding: 1rem !important; }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 1rem !important; }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 1rem !important; }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 1rem !important; }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 1rem !important; }
  .p-lg-2 {
    padding: 1.5rem !important; }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1.5rem !important; }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1.5rem !important; }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1.5rem !important; }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1.5rem !important; }
  .p-lg-3 {
    padding: 2rem !important; }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 2rem !important; }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 2rem !important; }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 2rem !important; }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 2rem !important; }
  .p-lg-4 {
    padding: 2.5rem !important; }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 2.5rem !important; }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 2.5rem !important; }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 2.5rem !important; }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 2.5rem !important; }
  .p-lg-5 {
    padding: 3rem !important; }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 3rem !important; }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 3rem !important; }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 3rem !important; }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 3rem !important; }
  .p-lg-6 {
    padding: 3.5rem !important; }
  .pt-lg-6,
  .py-lg-6 {
    padding-top: 3.5rem !important; }
  .pr-lg-6,
  .px-lg-6 {
    padding-right: 3.5rem !important; }
  .pb-lg-6,
  .py-lg-6 {
    padding-bottom: 3.5rem !important; }
  .pl-lg-6,
  .px-lg-6 {
    padding-left: 3.5rem !important; }
  .p-lg-7 {
    padding: 4rem !important; }
  .pt-lg-7,
  .py-lg-7 {
    padding-top: 4rem !important; }
  .pr-lg-7,
  .px-lg-7 {
    padding-right: 4rem !important; }
  .pb-lg-7,
  .py-lg-7 {
    padding-bottom: 4rem !important; }
  .pl-lg-7,
  .px-lg-7 {
    padding-left: 4rem !important; }
  .m-lg-auto {
    margin: auto !important; }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important; }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important; }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important; }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important; } }

/* ==========================================================================
   Vendor
   ========================================================================== */
/** Third party components. */
/**
 * vendor/_critical.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/*! sanitize.css v8.0.0 | CC0 License | github.com/csstools/sanitize.css */
/* Document
 * ========================================================================== */
/**
 * 1. Remove repeating backgrounds in all browsers (opinionated).
 * 2. Add border box sizing in all browsers (opinionated).
 */
*,
::before,
::after {
  background-repeat: no-repeat;
  /* 1 */
  box-sizing: border-box;
  /* 2 */ }

/**
 * 1. Add text decoration inheritance in all browsers (opinionated).
 * 2. Add vertical alignment inheritance in all browsers (opinionated).
 */
::before,
::after {
  text-decoration: inherit;
  /* 1 */
  vertical-align: inherit;
  /* 2 */ }

/**
 * 1. Use the default cursor in all browsers (opinionated).
 * 2. Use the default user interface font in all browsers (opinionated).
 * 3. Correct the line height in all browsers.
 * 4. Use a 4-space tab width in all browsers (opinionated).
 * 5. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 * 6. Breaks words to prevent overflow in all browsers (opinionated).
 */
html {
  cursor: default;
  /* 1 */
  font-family: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  /* 2 */
  line-height: 1.15;
  /* 3 */
  -moz-tab-size: 4;
  /* 4 */
  tab-size: 4;
  /* 4 */
  -ms-text-size-adjust: 100%;
  /* 5 */
  -webkit-text-size-adjust: 100%;
  /* 5 */
  word-break: break-word;
  /* 6 */ }

/* Sections
 * ========================================================================== */
/**
 * Remove the margin in all browsers (opinionated).
 */
body {
  margin: 0; }

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Firefox, and Safari.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/* Grouping content
 * ========================================================================== */
/**
 * 1. Add the correct sizing in Firefox.
 * 2. Show the overflow in Edge and IE.
 */
hr {
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */ }

/**
 * Add the correct display in IE.
 */
main {
  display: block; }

/**
 * Remove the list style on navigation lists in all browsers (opinionated).
 */
nav ol,
nav ul {
  list-style: none; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
pre {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/* Text-level semantics
 * ========================================================================== */
/**
 * Remove the gray background on active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Add the correct text decoration in Edge, IE, Opera, and Safari.
 */
abbr[title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted; }

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */
b,
strong {
  font-weight: bolder; }

/**
 * 1. Use the default monospace user interface font
 *    in all browsers (opinionated).
 * 2. Correct the odd `em` font sizing in all browsers.
 */
code,
kbd,
samp {
  font-family: Menlo, Consolas, Roboto Mono, Ubuntu Monospace, Noto Mono, Oxygen Mono, Liberation Mono, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */ }

/**
 * Add the correct font size in all browsers.
 */
small {
  font-size: 80%; }

/*
 * Remove the text shadow on text selections in Firefox 61- (opinionated).
 * 1. Restore the coloring undone by defining the text shadow
 *    in all browsers (opinionated).
 */
::-moz-selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

::selection {
  background-color: #b3d4fc;
  /* 1 */
  color: #000;
  /* 1 */
  text-shadow: none; }

/* Embedded content
 * ========================================================================== */
/*
 * Change the alignment on media elements in all browsers (opinionated).
 */
audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

/**
 * Add the correct display in IE 9-.
 */
audio,
video {
  display: inline-block; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Remove the border on images inside links in IE 10-.
 */
img {
  border-style: none; }

/**
 * Change the fill color to match the text color in all browsers (opinionated).
 */
svg:not([fill]) {
  fill: currentColor; }

/**
 * Hide the overflow in IE.
 */
svg:not(:root) {
  overflow: hidden; }

/* Tabular data
 * ========================================================================== */
/**
 * Collapse border spacing in all browsers (opinionated).
 */
table {
  border-collapse: collapse; }

/* Forms
 * ========================================================================== */
/**
 * Inherit styling in all browsers (opinionated).
 */
button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

/**
 * Remove the margin in Safari.
 */
button,
input,
select {
  margin: 0; }

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge, Firefox, and IE.
 */
button {
  overflow: visible;
  /* 1 */
  text-transform: none;
  /* 2 */ }

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button; }

/**
 * Correct the padding in Firefox.
 */
fieldset {
  padding: 0.35em 0.75em 0.625em; }

/**
 * Show the overflow in Edge and IE.
 */
input {
  overflow: visible; }

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */
legend {
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  white-space: normal;
  /* 1 */ }

/**
 * 1. Add the correct display in Edge and IE.
 * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */
progress {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */ }

/**
 * Remove the inheritance of text transform in Firefox.
 */
select {
  text-transform: none; }

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 * 3. Change the resize direction on textareas in all browsers (opinionated).
 */
textarea {
  margin: 0;
  /* 1 */
  overflow: auto;
  /* 2 */
  resize: vertical;
  /* 3 */ }

/**
 * Remove the padding in IE 10-.
 */
[type="checkbox"],
[type="radio"] {
  padding: 0; }

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */
[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */ }

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */
::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto; }

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */
::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54; }

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */
::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */
::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */ }

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */
::-moz-focus-inner {
  border-style: none;
  padding: 0; }

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */
:-moz-focusring {
  outline: 1px dotted ButtonText; }

/* Interactive
 * ========================================================================== */
/*
 * Add the correct display in Edge and IE.
 */
details {
  display: block; }

/*
 * Add the correct styles in Edge, IE, and Safari.
 */
dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content; }

dialog:not([open]) {
  display: none; }

/*
 * Add the correct display in all browsers.
 */
summary {
  display: list-item; }

/* Scripting
 * ========================================================================== */
/**
 * Add the correct display in IE 9-.
 */
canvas {
  display: inline-block; }

/**
 * Add the correct display in IE.
 */
template {
  display: none; }

/* User interaction
 * ========================================================================== */
/*
 * 1. Remove the tapping delay in IE 10.
 * 2. Remove the tapping delay on clickable elements
      in all browsers (opinionated).
 */
a,
area,
button,
input,
label,
select,
summary,
textarea,
[tabindex] {
  /* 1 */
  touch-action: manipulation;
  /* 2 */ }

/**
 * Add the correct display in IE 10-.
 */
[hidden] {
  display: none; }

/* Accessibility
 * ========================================================================== */
/**
 * Change the cursor on busy elements in all browsers (opinionated).
 */
[aria-busy="true"] {
  cursor: progress; }

/*
 * Change the cursor on control elements in all browsers (opinionated).
 */
[aria-controls] {
  cursor: pointer; }

/*
 * Change the cursor on disabled, not-editable, or otherwise
 * inoperable elements in all browsers (opinionated).
 */
[aria-disabled="true"],
[disabled] {
  cursor: not-allowed; }

/*
 * Change the display on visually hidden accessible elements
 * in all browsers (opinionated).
 */
[aria-hidden="false"][hidden]:not(:focus) {
  clip: rect(0, 0, 0, 0);
  display: inherit;
  position: absolute; }

/* ==========================================================================
   Generic
   ========================================================================== */
/** Low-specificity, far-reaching rulesets. */
/**
 * generic/_critical.scss
 */
.no-scroll {
  overflow: hidden; }

/* ==========================================================================
   Base
   ========================================================================== */
/** Unclassed HTML elements. */
/**
 * base/_critical.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/* ==========================================================================
   Objects
   ========================================================================== */
/** Design-free objects, abstractions and patterns. */
/**
 * objects/_critical.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/* Extends
   ========================================================================== */
/* Main
   ========================================================================== */
/* ==========================================================================
   Components
   ========================================================================== */
/** Components and widgets. */
/**
 * components/_critical.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */
/**
 * components/_app.scss
 */
.app {
  position: relative;
  width: 100%; }

/**
 * components/_header.scss
 */
.header {
  width: 100%;
  height: auto;
  padding-top: 9.375rem;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6D2B70+0,b4196b+100 */
  background: #6D2B70;
  /* Old browsers */
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: -webkit-gradient(linear, left top, left bottom, from(#6D2B70), to(#b4196b));
  background: linear-gradient(to bottom, #6D2B70 0%, #b4196b 100%);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6D2B70', endColorstr='#b4196b',GradientType=0 );
  /* IE6-9 */ }
  @media (max-width: 991.98px) {
    .header {
      padding-top: 2.57rem; } }
  .header .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". . . p p p p p p . logo logo" ". . . h1 h1 h1 h1 h1 h1 . . ."; }
    @media (max-width: 991.98px) {
      .header .container {
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-areas: "logo logo logo logo" "p p p p" "h1 h1 h1 h1"; } }
    .header .container h1 {
      width: 100%;
      grid-area: h1;
      word-break: keep-all; }
      .header .container h1 span {
        display: inline;
        white-space: nowrap; }
    .header .container p {
      width: 100%;
      grid-area: p;
      font-size: 1.875rem;
      line-height: 1.65em; }
      @media (max-width: 991.98px) {
        .header .container p {
          margin-top: .4rem;
          margin-bottom: 1.1875rem;
          max-width: 100%; } }
    .header .container .logo {
      display: flex;
      width: 100%;
      grid-area: logo;
      justify-content: flex-end;
      align-self: start; }
      @media (max-width: 991.98px) {
        .header .container .logo {
          justify-content: center;
          margin-bottom: 1.91rem; } }
      .header .container .logo a {
        width: 14.75rem;
        display: block;
        text-decoration: none; }
        @media (max-width: 991.98px) {
          .header .container .logo a {
            width: 48%; } }
  .header .logo-svg {
    display: inline-block;
    height: auto; }
    .header .logo-svg i, .header .logo-svg svg {
      width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0; }

h1 {
  font-size: 3.75rem;
  line-height: 4.3125rem;
  font-weight: normal; }
  @media (min-width: 1280px) {
    h1 {
      font-size: 3.75rem;
      line-height: 4.3125rem;
      font-weight: normal; } }
  @media (max-width: 1279.98px) {
    h1 {
      font-size: 3rem;
      line-height: 4rem; } }
  @media (max-width: 991.98px) {
    h1 {
      font-size: 2rem;
      line-height: 3rem; } }

h2 {
  font-size: 2.8125rem;
  line-height: 3.8125rem;
  font-weight: bold; }

h3 {
  font-size: 1.875rem;
  line-height: 2.875rem;
  font-weight: bold; }
  @media (max-width: 991.98px) {
    h3 {
      font-size: 1.575rem; } }

h4 {
  font-size: 1.5rem;
  line-height: 2.875rem;
  font-weight: bold; }

h5 {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: bold; }

p {
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: bold; }

.text-color-primary {
  color: var(--colorPrimary); }

.text-color-secondary {
  color: var(--colorSecondary); }

.text-color-tertiary {
  color: var(--colorTertiary); }

.text-color-quaternary {
  color: var(--colorQuaternary); }

/**
 * components/_button.scss
 */
button, .button {
  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  padding-top: .875rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: .875rem;
  color: var(--colorSecondary);
  background-color: var(--colorPrimary);
  border-radius: .625rem;
  outline: none;
  cursor: pointer;
  border: none;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  align-self: center; }
  button:before, .button:before {
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: var(--colorSenary);
    top: 0;
    left: 0;
    content: '';
    -webkit-transition: all 0.6s cubic-bezier(0.39, 0.58, 0.54, 1.1);
    transition: all 0.6s cubic-bezier(0.39, 0.58, 0.54, 1.1);
    border-radius: .625rem; }
  button span, .button span {
    color: var(--colorsecondary);
    mix-blend-mode: hard-light; }
  button:hover::before, .button:hover::before {
    width: 100%; }

/* ==========================================================================
   Trumps
   ========================================================================== */
/** Helper classes and overrides. */
/**
 * trumps/_critical.scss
 */
/* ==========================================================================
   Imports
   ========================================================================== */

