/**
 * components/_card.scss
 */
.card {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 3.75rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3.75rem;
  background-color: #F7E6A2;
  border-radius: .625rem;
  align-items: center;
  justify-content: space-between;

  @include media-breakpoint-down(md){
    margin-bottom: 2.625rem;
  }

  @include media-breakpoint-down(sm){
    padding-top: 1.8125rem;
    padding-bottom: 1.8125rem;
  }

  h4 {
    color: var(--colorTertiary);
    display: inline-block;
  }

  span {
    display: inline-block;
    max-width: 21rem;
    font-size: 1.5rem;
    line-height: 2.875rem;
    margin-top: 3rem;
    color: var(--colorPrimary);

    @include media-breakpoint-down(sm){
      max-width: 11.2rem;
      margin-top: .6875rem;
    }
  }
}
