/**
 * components/_button.scss
 */

button, .button {

  position: relative;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
  padding-top: .875rem;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: .875rem;
  color: var(--colorSecondary);
  background-color: var(--colorPrimary);
  border-radius: .625rem;
  outline: none;
  cursor: pointer;
  border: none;
  overflow: hidden;
  text-decoration: none;
  text-align: center;
  align-self: center;

  &:before {
    position: absolute;
    width: 0%;
    height: 100%;
    background-color: var(--colorSenary);
    top: 0;
    left: 0;
    content: '';
    transition: all 0.6s cubic-bezier(0.39, 0.58, 0.54, 1.1);
    border-radius: .625rem;
  }

  span {
    color: var(--colorsecondary);
    mix-blend-mode: hard-light;
  }

  &:hover{
    &::before{
      width: 100%;
    }
  }

}
