/**
 * objects/_locked.scss
 */

 .locked {

  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#b4196b+0,872d6a+49,bc213b+100 */
  background: #b4196b; /* Old browsers */
  background: -moz-linear-gradient(top,  #b4196b 0%, #872d6a 49%, #bc213b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #b4196b 0%,#872d6a 49%,#bc213b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #b4196b 0%,#872d6a 49%,#bc213b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4196b', endColorstr='#bc213b',GradientType=0 ); /* IE6-9 */


  @include media-breakpoint-down(sm){
    padding-top: 1.1875rem;
    padding-bottom: 1.1875rem;
  }

  .title {
    width: 100%;

    @include media-breakpoint-down(sm){
      margin-top: 2.75rem;
    }
  }

}
