/**
 * objects/_calendary.scss
 */
.calendary {
  width: 100%;
  height: auto;
  padding-top: 4.625rem;
  padding-bottom: 4rem;

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#bc213b+0,eb6625+100 */
  background: #bc213b; /* Old browsers */
  background: -moz-linear-gradient(top,  #bc213b 0%, #eb6625 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #bc213b 0%,#eb6625 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #bc213b 0%,#eb6625 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#bc213b', endColorstr='#eb6625',GradientType=0 ); /* IE6-9 */

  @include media-breakpoint-down(sm){
    padding-top: 1rem;
  }

  .container {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: ". cards cards cards cards cards cards cards cards cards cards .";

    @include media-breakpoint-down(sm){
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas: "cards cards cards cards";
    }
  }

  .cards {
    display: grid;
    width: 100%;
    grid-area: cards;
    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 1rem;

    @include media-breakpoint-down(md){
      grid-template-columns: 1fr;
    }

  }
}
