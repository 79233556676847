/**
 * components/_results.scss
 */

.results {

  width: 100%;
  height: auto;
  padding-top: 7.5rem;
  background: rgb(180,25,107);
  background: -moz-linear-gradient(top,  rgba(180,25,107,1) 0%, rgba(153,36,106,1) 100%);
  background: -webkit-linear-gradient(top,  rgba(180,25,107,1) 0%,rgba(153,36,106,1) 100%);
  background: linear-gradient(to bottom,  rgba(180,25,107,1) 0%,rgba(153,36,106,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b4196b', endColorstr='#99246a',GradientType=0 );

  @include media-breakpoint-down(sm) {
    padding-top: 3.5rem;
  }

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: auto;

    @include media-breakpoint-down(sm) {
      grid-template-columns: 1fr;
    }
  }

  .h3 {
    max-width: 900px;
  }

}
