/**
 * objects/_locations.scss
 */

.-border-bottom {
  padding-bottom: 4rem;
  border-bottom: 1px solid lightyellow;
}

.locations {

  width: 100%;
  height: auto;
  padding-top: 3.1875rem;
  padding-bottom: 5.4375rem;
  background-color: var(--colorPrimary);

  @include media-breakpoint-down(sm){
    padding-bottom: 0;
  }

  .container {

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: auto;
    grid-template-areas:
      "title title title title title title title title title title title title"
      "locations locations locations locations locations locations locations locations locations locations locations locations";

    @include media-breakpoint-down(md){

      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-template-areas:
      "title title title title"
      "locations locations locations locations";
    }

    h3 {
      grid-area: title;

      &.-small {
        font-size: 1.4rem;
        padding: 1rem 0 0;
      }
    }

    .container-locations {
      display: flex;
      width: 100%;
      flex-direction: row;
      grid-area: locations;
      margin-top: 3.1875rem;
      justify-content: space-between;

      @include media-breakpoint-down(md){
        flex-direction: column;
      }

      .item {
        flex-grow: 1;

        h4 {
          margin-bottom: 2rem;
        }

        @include media-breakpoint-down(md){
          margin-bottom: 5rem;
        }
      }
    }
  }
}
