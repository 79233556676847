/**
 * components/_card_radio_button.scss
 */
.card-radio-button {

  position: relative;
  width: 100%;

  /* ----------------------------------------------
  * Generated by Animista on 2019-12-9 16:45:12
  * Licensed under FreeBSD License.
  * See http://animista.net/license for more info.
  * w: http://animista.net, t: @cssanimista
  * ---------------------------------------------- */

  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */
  @-webkit-keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
  }
  @keyframes scale-down-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
  }

  /**
  * ----------------------------------------
  * animation scale-down-center
  * ----------------------------------------
  */
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }



  input[type='radio']{
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: -1;

    &:not(:checked) + label .content{
      -webkit-animation: scale-up-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: scale-up-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &:checked + label .content {
      -webkit-animation: scale-down-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	    animation: scale-down-center 0.3s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    &:checked + label:before{
      position: absolute;
      width: 100%;
      height: 100%;
      border: 1px solid #fff;
      border-radius: 1.25rem;
      overflow: hidden;
    }

  }

  label {

    display: block;
    width: 100%;
    height: auto;

    &:before {
      content: '';
    }

    .content {

      display: block;
      width: 100%;
      height: auto;

      picture {

        display: block;
        width: 100%;
        height: auto;

        border-radius: 1.125rem;
        overflow: hidden;

        img {
          width: 100%;
          height: auto;
        }

      }

      .check {

        position: absolute;
        width: 2.9rem;
        height: 2.9rem;
        top: 1rem;
        right: 1rem;
        background-color: rgba(255, 255, 255, .85);
        border-radius: 1.45rem;
        border: 1px solid rgba(125, 125, 125, .6);

        @include media-breakpoint-down(sm){
          width: 2.5rem;
          height: 2.5rem;
          top: .75rem;
          right: .75rem;
        }

        .animation {
          position: absolute;
          width: 5.9375rem;
          height: 5.9375rem;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 10;
          margin-top: .1rem;

          @include media-breakpoint-down(sm){
            width: 5rem;
            height: 5rem;
          }
        }

        span, svg{
          width: 100%;
          height: 100%;
          display: block;
        }
      }

    }

  }

}
