// components/_typography.scss

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: 3.75rem;
  line-height: 4.3125rem;
  font-weight: normal;

  @include media-breakpoint-up(lg){
    font-size: 3.75rem;
    line-height: 4.3125rem;
    font-weight: normal;
  }

  @include media-breakpoint-down(md){
    font-size: 3rem;
    line-height: 4rem;
  }

  @include media-breakpoint-down(sm){
    font-size: 2rem;
    line-height: 3rem;
  }

}

h2 {
  font-size: 2.8125rem;
  line-height: 3.8125rem;
  font-weight: bold;
}

h3 {
  font-size: 1.875rem;
  line-height: 2.875rem;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    font-size: 1.575rem;
  }
}

h4 {
  font-size: 1.5rem;
  line-height: 2.875rem;
  font-weight: bold;
}

h5 {
  font-size: 1.375rem;
  line-height: 1.875rem;
  font-weight: bold;
}

p {
  font-size: 1.375rem;
  line-height: 2rem;
  font-weight: bold;
}

.text-color {

  &-primary {
    color: var(--colorPrimary);
  }

  &-secondary {
    color: var(--colorSecondary);
  }

  &-tertiary {
    color: var(--colorTertiary);
  }

  &-quaternary {
    color: var(--colorQuaternary);
  }

}
