/**
 * components/_form.scss
 */

.form {

  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 50.75rem;
  background-color: var(--colorTertiary);
  border-radius: .875rem;

  padding-top: 3.875rem;
  padding-left: 6.9rem;
  padding-right: 6.9rem;
  padding-bottom: 4.375rem;

  @include media-breakpoint-down(sm){

    padding-top: 3.125rem;
    padding-left: 6.54%;
    padding-right: 6.54%;
    padding-bottom: 3.06rem;

  }

  h4{
    width: 100%;

    @include media-breakpoint-down(sm){
      font-size: 1.125rem;
      line-height: 1.75rem;
    }
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .form-group {
    width: 90%;
    margin-top: 2.875rem;

    @include media-breakpoint-down(sm){
      margin-top: 2.06rem;
    }

    label {
      display: inline-block;
      font-size: 1.375rem;
      line-height: 1.875rem;

      @include media-breakpoint-down(sm){
        font-size: 1.125rem;
        line-height: 1.75rem;
      }
    }

    .form-control {
      width: 100%;
      margin-top: 1.125rem;
    }

    small {
      display: inline-block;
      font-size: 1rem;
      line-height: 1.375rem;
      margin-top: .5rem;
      color: var(--colorQuinary);
    }

    button, a{
      width: 100%;
      display: inline-block;
    }

    .loading {
      position: relative;
      width: 100%;
      height: 70px;
      display: flex;
      justify-content: center;

      div {
        position: absolute;
        width: auto !important;
        height: auto !important;
        top: 50%;
        transform: translate(0%, -50%);
      }
    }

  }

  .share {

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;


    @include media-breakpoint-down(md){
      flex-direction: column;
    }

    h4{
      width: auto;
      display: inline-flex;
    }

    .share-buttons {

      display: inline;
      margin-left: .8rem;

      @include media-breakpoint-down(md){
        margin-left: 0;
      }

      div {
        display: inline;
        margin-left: .1rem;
        margin-right: .1rem;
        cursor: pointer;
        outline: none;
      }
    }

  }

  .animation-success
  {
    position: fixed;
    width: 40%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -60%);

    @include media-breakpoint-down(sm){
      width: 100%;
      transform: translate(-50%, -50%);
    }

  }


}

.slide-in-bottom {
	-webkit-animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s both;
	        animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2019-12-13 17:10:8
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-bottom
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
            transform: translateY(1000px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
    opacity: 1;
  }
}
