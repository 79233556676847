/**
 * settings/_main.scss
 */

 :root {
  font-size: 16px;
}

// colors
$color-primary: #EB6625;
$color-secondary: #fff;
$color-tertiary: #273E76;
$color-quaternary: #B4196B;
$color-quinary: #EC6906;
$color-senary: #BC213B;

// fonts
$font-primary: 'Nunito', sans-serif;

// container limits
$grid-breakpoints:(
  xs: 0,
  sm: 576px,
  md: 992px,
  lg: 1280px
);

$container-padding: 7.299;

* {
  text-rendering: optimizeLegibility;
  -webkit-tap-highlight-color: transparent;
}

body {
  --colorPrimary: #{$color-primary};
  --colorSecondary: #{$color-secondary};
  --colorTertiary: #{$color-tertiary};
  --colorQuaternary: #{$color-quaternary};
  --colorQuinary: #{$color-quinary};
  --colorSenary: #{$color-senary};

  --fontPrimary: #{$font-primary};

  background: var(--colorSecondary);
  color: var(--colorSecondary);
  font: 400 1rem/1.7 var(--fontPrimary);
}

img {
  max-width: 100%;
}

a {
  color: inherit;
}

// spacers
$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
  0: 0,
  1: ($spacer * 1),
  2: ($spacer * 1.5),
  3: ($spacer * 2),
  4: ($spacer * 2.5),
  5: ($spacer * 3),
  6: ($spacer * 3.5),
  7: ($spacer * 4)
), $spacers);
