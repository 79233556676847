/**
 * generic/_container.scss
 */

.container {
  display: grid;
  width: 100%;
  margin: 0 auto;
  grid-column-gap: 1rem;

  @include media-breakpoint-down(lg){
    padding-left: $container-padding * 1%;
    padding-right: $container-padding * 1%;
  }
}
