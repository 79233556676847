/**
 * generic/_main.scss
 */

/* ==========================================================================
   Imports
   ========================================================================== */

@import "fonts";
@import "container";

::selection {
  background-color: var(--colorSecondary);
  color: var(--colorTertiary);
}

// Centraliza
.center {
  text-align: center;
}

.uppercase {
  text-transform: uppercase;
}



@keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0
  }
  100% {
    background-position: 468px 0
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: #e3e3e3;
  background-image: -webkit-linear-gradient( left, #e3e3e3 0%, #edeef1 20%, #e3e3e3 40%, #e3e3e3 100%);
}
