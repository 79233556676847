/**
 * components/_profile.scss
 */

.profile {

  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  picture {
    width: 100%;
    height: fit-content;
    max-width: 5.625rem;
    max-height: 5.625rem;
    border-radius: 100%;
    overflow: hidden;
    flex-shrink: 0;

    @include media-breakpoint-down(sm){
      grid-area: photo;
      max-width: 4.25rem;
      max-height: 4.25rem;
    }
  }

  .name {
    grid-area: nameProfile;
    white-space: nowrap;
    margin-left: 20px;
  }

  .social {
    width: auto;
  }
}
