/**
 * components/_social.scss
 */

.social {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  #gradient {
    width:0;height:0;position:absolute;
  }

  a {
    width: 100%;
    max-width: 1.8125rem;
    height: auto;
    text-decoration: none;
    font-size: 0;
    margin-right: 1.25rem;

    span, i, svg {
      display: block;
      width: 100%;
      height: 100%;
    }

    svg {

      path {
        transition: all 1s ease-out;
        fill: var(--colorSecondary);
      }


    }

    &:hover{

      svg {
        path {
          transition: all 1s ease-out;
          fill: url(#my-share-gradient) var(--colorSecondary);

        }

      }

    }

  }

}
