/**
 * components/_artist.scss
 */

.artist {

  width: 100%;
  padding: 0;

  @include media-breakpoint-up(lg) {
    padding-left: 12.57%;
    padding-right: 12.57%;
  }

  @include media-breakpoint-up(md) {
    padding-left: 7.2%;
    padding-right: 7.2%;
  }

  @include media-breakpoint-down(sm) {
    margin-bottom: 3.2rem;
  }

  .info-container {
    width: 100%;
  }

  .cards-container {

    .item {

      &:nth-of-type(2), &:nth-of-type(3) {
        display: none;
      }
    }
  }
}
