/**
 * components/_header.scss
 */
.header {
  width: 100%;
  height: auto;
  padding-top: 9.375rem;

  @include media-breakpoint-down(sm){
    padding-top: 2.57rem;
  }

  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6D2B70+0,b4196b+100 */
  background: #6D2B70; /* Old browsers */
  background: -moz-linear-gradient(top,  #6D2B70 0%, #b4196b 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  #6D2B70 0%,#b4196b 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  #6D2B70 0%,#b4196b 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6D2B70', endColorstr='#b4196b',GradientType=0 ); /* IE6-9 */

  .container {

    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      ". . . p p p p p p . logo logo"
      ". . . h1 h1 h1 h1 h1 h1 . . .";

    @include media-breakpoint-down(sm){

      grid-template-columns: 1fr 1fr 1fr 1fr;

      grid-template-areas:
        "logo logo logo logo"
        "p p p p"
        "h1 h1 h1 h1";
    }

    h1 {
      width: 100%;
      grid-area: h1;
      word-break: keep-all;

      span {
        display: inline;
        white-space: nowrap;
      }

    }

    p {
      width: 100%;
      grid-area: p;
      font-size: 1.875rem;
      line-height: 1.65em;

      @include media-breakpoint-down(sm){
        margin-top: .4rem;
        margin-bottom: 1.1875rem;
        max-width: 100%;
      }
    }

    .logo {
      display: flex;
      width: 100%;
      grid-area: logo;
      justify-content: flex-end;
      align-self: start;

      @include media-breakpoint-down(sm){
        justify-content: center;
        margin-bottom: 1.91rem;
      }

      a {
        width: 14.75rem;
        display: block;
        text-decoration: none;

        @include media-breakpoint-down(sm){
          width: 48%;
        }
      }

    }

  }

  .logo-svg {
    display: inline-block;
    height: auto;

    i, svg {
      width: 100%;
    }

  }

}
